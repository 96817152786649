import React from "react"
import { navigate } from "gatsby"

import CheckoutWrapper from "../../components/checkout/checkout-wrapper"
import { isBrowser } from "../../lib/utils"

const Checkout = ({ location }) => {
  // We don't actually use the /checkout/ path, so redirect to /checkout/delivery/
  if (isBrowser()) {
    navigate("/checkout/delivery/", { replace: true })
  }

  return <CheckoutWrapper skipRedirect={true} location={location} />
}

export default Checkout
